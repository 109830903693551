const emailRegex = /\S+@\S+\.\S+/;
// Validation for Create Project Modal
export const generalValidation = (values) => {
    let errors = {}

    if (!values.project_name) {
        errors.project_name = "Please enter Project Name";
    }
    if (!values.project_location) {
        errors.project_location = "Please enter Project Location";
    }
    if (!values.project_start_date) {
        errors.project_start_date = "Please select Start Date";
    }
    if (!values.project_end_date) {
        errors.project_end_date = "Please select End Date";
    }
    if (!values.project_join_as) {
        errors.project_join_as = "Please enter your role (Like:- Admin or user)";
    }
    if (!values.project_type_of_giving) {
        errors.project_type_of_giving = "Please select Type of Giving";
    }
    if (!values.project_target_amount) {
        errors.project_target_amount = "Please enter Target Amount";
    }
    return errors;
}
// Validation for Create Project General Optional Modal
export const generalOptionalValidation = (values) => {
    let errors = {}
    // console.log(values)
    if (!values.project_id) {
        errors.project_id = "Please enter Project ID";
    }
    if (!values.project_description) {
        errors.project_description = "Please enter Project Description";
    }
    if (!values.project_visibility) {
        errors.project_visibility = "Please select project visibility option";
    }
    return errors;
}

// Validation for Group Details Modal
export const groupDetailsValidation = (values) => {
    let errors = {}
    
    if (!values.groupEmail) {
        errors.groupEmail="Email is required";
    }
    if (!emailRegex.test(values.groupEmail)) {
        errors.groupEmail="Please enter Valid Email";
    }
    if (!values.groupName) {
        errors.groupName="Group Name is required";
    }
    if (!values.pin) {
        errors.pin="Group pin is required";
    }
    if (!values.type) {
        errors.type="Group type is required";
    }
    if (!values.duration) {
        errors.duration="Group duration is required";
    }
    if (!values.city) {
        errors.city="City is required";
    }
    if (!values.groupPhone) {
        errors.groupPhone="Phone number is required";
    }
    if (values.groupPhone < 10) {
        errors.groupPhone="Phone number must be 10 digit";
    }
    return errors;
}

// Validation for Business Details Modal
export const businessDetailsValidation = (values) => {
    let errors = {}
    if (!values.business_name) {
        errors.business_name="Business Name is required";
    }
    if (!values.business_email) {
        errors.business_email="Email is required";
    }
    if (!emailRegex.test(values.business_email)) {
        errors.business_email="Please enter Valid Email";
    }
    if (!values.business_number) {
        errors.business_number="Registered Business number is required";
    }
    if (!values.business_phonenumber) {
        errors.business_phonenumber="Phone number is required";
    }
    if (values.business_phonenumber < 10) {
        errors.business_phonenumber="Phone number must be 10 digit";
    }
    if (!values.business_city) {
        errors.business_city="City is required";
    }
    if (!values.business_address) {
        errors.business_address="Address is required";
    }
    if (!values.business_postcode) {
        errors.business_postcode="Postcode is required";
    }
    return errors;
}