export const registerUserReducer = (state = {}, action) => {
  // console.log(action);

  switch (action.type) {
    case "USER_REGISTER_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "USER_REGISTER_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        register:action.payload
      };
    case "USER_REGISTER_FAILED":
      return {
        ...state,
        loading: false,
        error: "User Already Registred",
      };

    default:
      return state;
  }
};

export const loginReducer = (state = {}, action) => {
  // console.log(action.type);
  
  switch (action.type) {
    case "USER_LOGIN_REQUEST":
      console.log(state)
      return {
        ...state,
        loading: true,
      };
    case "USER_LOGIN_SUCCESS":
      return {
        ...state,
        success: true,
        loading: false,
      };
    case "USER_LOGIN_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
      };
    case "USER_LOGOUT":
      return {
        ...state,
      };
    default:
      return state;
  }
};

export const updateReducer = (state = {}, action) => {
  switch (action.type) {
    case "USER_UPDATE_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "USER_UPDATE_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
      };
    case "USER_UPDATE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload.response.data.message,
      };
    default:
      return state;
  }
};

export const getAllUserReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case "GET_ALLUSERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_ALLUSERS_SUCCESS":
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    case "GET_ALLUSERS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteUserReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELETE_USERS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "DELETE_USERS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
      };
    case "DELETE_USERS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const formReducer = (state = {}, action) => {
  switch (action.type) {
    case "PERSONAL_DETAILS":
      return {
        ...state,
        obj: action.payload,
      };
    case "ADDRESS_DETAILS":
      return {
        ...state,
        addressdata: action.payload,
      };
    case "GROUP_DETAILS":
      return {
        ...state,
        groupData: action.payload,
      };
    case "BUSINESS_DETAILS":
      return {
        ...state,
        BusinessData: action.payload,
      };
    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case "FORGOT_PASSWORD_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "FORGOT_PASSWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
      };
    case "FORGOT_PASSWORD_FAILED":
      return {
        ...state,
        loading: false,
        error: "forgot password failed",
      };

    default:
      return state;
  }
};

export const NewProjectReducer = (state = {}, action) => {
  // console.log(action.type);

  switch (action.type) {
    case "CREATE_NEW_PROJECT":
      return {
        ...state,
        loading: true,
      };
    case "PROJECT_CREATED":
      return {
        ...state,
        loading: false,
        success: true,
      };
    case "PROJECT_CREATION_FAILED":
      return {
        ...state,
        loading: false,
        error: "Project alredy cerated",
      };

    default:
      return state;
  }
};

export const getAllProjectsReducer = (state = { project: [] }, action) => {
  // console.log(state, action.payload)
  // console.log(state, "action All Projects = "+action.payload)

  switch (action.type) {
    case "GET_ALL_PROJECTS":
      return {
        ...state,
        loading: true,
      };
    case "GET_ALLPROJECTS_SUCCESS":
      return {
        ...state,
        loading: false,
        project: action.payload.data,
      };
    case "GET_ALLPROJECTS_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getUserTypeReducer = (state={}, action) => {
  // console.log(state, "action User Type = "+ action.payload)
  switch (action.type) {
    case "GET_USERTYPES":
      return {
        ...state,
        loading: true,
      };
    case "GET_USERTYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        userTypeData: action.payload,
      };
    case "GET_USERTYPE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateUserTypeReducer = (state={}, action) => {
  // console.log(state, "action User Type = "+ action.payload)
  switch (action.type) {
    case "UPDATE_USERTYPES":
      return {
        ...state,
        loading: true,
      };
    case "UPDATE_USERTYPE_SUCCESS":
      return {
        ...state,
        loading: false,
        userTypeData: action.payload,
      };
    case "UPDATE_USERTYPE_FAILED":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};