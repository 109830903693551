import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import {
  formReducer,
  loginReducer,
  registerUserReducer,
  getAllProjectsReducer,
  getUserTypeReducer,
  updateReducer,
  updateUserTypeReducer
} from "./reducers/user.reducer";

const finalReducer = combineReducers({
  loginReducer: loginReducer,
  registerUserReducer: registerUserReducer,
  getAllProjectsReducer: getAllProjectsReducer,
  getUserTypeReducer: getUserTypeReducer,
  updateReducer:updateReducer,
  updateUserTypeReducer:updateUserTypeReducer,
  form: formReducer,
});

const currentUser = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser"))
  : null;

const initialReducer = {
  loginReducer: { currentUser: currentUser },
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  finalReducer,
  initialReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
