import React from "react";
import "../assets/css/spinner.css";
import user from "../assets/images/user-icon.png";
export default function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">

            </div>
        </div>
    );
}