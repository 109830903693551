import React, { useState } from "react";
import "./css/main.css";
import { useDispatch } from "react-redux";
import Navbar from "./components/navbar";
import SideNav from "./components/side-nav";
import ProjectHeader from "./components/ProjectHeader";
import ProjectButtons from "./components/ProjectButtons";
import ProjectRepot from "./components/ProjectRepot";
import ProjectList from "./components/ProjectList";
import { getAllProjects } from "../../redux/action/user.action";

import MyProject from "./components/MyProject";
import ExpensesDetails from "./components/ExpensesDetails";
import Loader from "../Loader";

const Project = () => {

  const dispatch = useDispatch();

  const [status, setStatus] = useState('')
  const [loadData, setLoadData] = useState(false)
  const [fetching, setFetching] = useState(false);
  const [pageNumber, setPageNumber] = useState('');
  const getProjects = (e) => {
    setStatus(e.target.value);
    dispatch(getAllProjects(e.target.value, pageNumber))
    setLoadData(true)
    setFetching(true)
    setTimeout(() => {
      setFetching(false)
    }, 1000)
  }

  return (
    <div className="theme-cyan">
      <div id="wrapper">
        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <Navbar />
          </div>
        </nav>

        <div id="left-sidebar" className="sidebar mini-sidebar">
          <div className="sidebar-scroll">
            <SideNav />
          </div>
        </div>

        <div id="main-content">
          <div className="container-fluid">
            <div className="block-header">
              <ProjectHeader />
            </div>

            <div className="row clearfix">
              <ProjectButtons getProjects={getProjects} loadData={loadData} status={status} />
            </div>



            <div className="row clearfix">
              <div className="col-lg-12">
                <ProjectList status={status} fetching={fetching} />
              </div>
            </div>

            <div className="row clearfix">
              <div className="col-lg-12">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
