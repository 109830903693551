import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
import "./user-dashboard/css/main.css";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 footer-contact">
              <Link to="/" className="logo me-auto">
                <img src={logo} alt="" className="img-fluid" />
              </Link>
              <p>
                <strong>Address: </strong> Address: 71-75 Shelton Street, <br />
                Covent Garden, London,
                <br />
                United Kingdom, WC2H 9JQ <br />
                <br />
                <strong>Email:</strong> info@example.com
                <br />
              </p>
            </div>
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Join Now</h4>
              <ul>
                <li>
                  <a href="register.html">Individual Membership</a>
                </li>
                <li>
                  <a href="register.html">Business Membership</a>
                </li>
                <li>
                  <a href="register.html">Group Membership</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/how-it-works">How it Work</Link>
                </li>
                <li>
                  <Link to="/how-it-works">Explore </Link>
                </li>
                <li>
                  <Link to="/how-it-works">Pricing</Link>
                </li>
                <li>
                  <Link to="/how-it-works">Meet Our Team</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Let’s stay in touch</h4>
              <div className="footer-subscribe">
                <form action="" method="post">
                  <input
                    type="email"
                    name="email"
                    placeholder=" your email address"
                  />
                  <input type="submit" value="Subscribe" />
                </form>
                <p>Keep up to date with our latest news and special offers.</p>
              </div>
              <div className="social-links mt-3">
                <Link to="/" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </Link>
                <Link to="/" className="facebook">
                  <i className="bx bxl-facebook"></i>
                </Link>
                <Link to="/" className="linkedin">
                  <i className="bx bxl-linkedin"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" footer-bottom clearfix">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3">
              <Link to="/how-it-works" target="_self">
                ©2022 Emembrs.com
              </Link>
            </div>
            <div className="col-12 col-md-3">
              <Link to="/how-it-works" target="_self">
                Privacy Policy
              </Link>
            </div>
            <div className="col-12 col-md-3">
              <Link to="/how-it-works" target="_self">
                Membership Terms &amp; Conditions
              </Link>
            </div>
            <div className="col-12 col-md-3">
              <Link to="/how-it-works" target="_self">
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
