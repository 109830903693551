import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "../css/main.css";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/action/user.action";
import user from "../../../assets/images/user-icon.png";
import overview_icon from "../../../assets/images/overview-icon.png";
import project_icon from "../../../assets/images/project-icon.png";
import member_icon from "../../../assets/images/member-icon.png";
import explore_icon from "../../../assets/images/explore-icon.png";
import wallet_icon from "../../../assets/images/wallet-icon.png";
import log_icon from "../../../assets/images/log-icon.png";

export default function SideNav() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [userName, setUserName] = useState('');
  const [userType, setUserType] = useState(0);
  const [member, setMember] = useState('');
  const [url, setUrl] = useState(null);

  useEffect(() => {
    setUrl(location.pathname);
    const getUser = JSON.parse(localStorage.getItem('currentUser'));
    if (getUser) {
      setUserName(getUser.data.first_name + " " + getUser.data.last_name);
      setUserType(getUser.data.user_type)
    }
    if (userType && !member) {
      if (userType === 1) {
        setMember('Individual')
      }
      else if (userType === 2) {
        setMember('Group')
      }
      else{
        setMember('Business')
      }
    }
    
  }, [location, userType, member]);
  return (
    <>
      <div className="user-account">
        <img
          src={user}
          className="rounded-circle user-photo"
          alt="User Profile Picture"
        />
        <div className="dropdown">
          <a
            href="javascript:void(0);"
            className="dropdown-toggle user-name"
            data-toggle="dropdown"
          >
            <strong className="text-capitalize">{userName}</strong>
          </a>
          <span>{member} Member</span>
          <ul className="dropdown-menu dropdown-menu-right account">
            <li>
              <a href="page-profile2.html">
                <i className="icon-user"></i>My Profile
              </a>
            </li>
            <li>
              <a href="app-inbox.html">
                <i className="icon-envelope-open"></i>Messages
              </a>
            </li>
            <li>
              <a href="javascript:void(0);">
                <i className="icon-settings"></i>Settings
              </a>
            </li>
            <li className="divider"></li>
            <li>
              <div className="sidenav-footer">
                <div className="sidenav-footer-content">
                  <div className="sidenav-footer-subtitle">Logged in as:</div>
                  <div className="sidenav-footer-title">Valerie Luna</div>
                </div>
              </div>
              <Link className="getstarted scrollto nav-link" to="/login" onClick={() => {
                dispatch(logoutUser());
              }}>
                <i className="icon-power"></i>Logout
              </Link>
            </li>
          </ul>
        </div>
        <hr />
        <ul className="row list-unstyled">
          <li className="col-6">
            <h6 style={{ fontWeight: 500 }}>£4000</h6>
            <small>Total Funds</small>
          </li>
          <li className="col-6">
            <h6 style={{ fontWeight: 500 }}>1350</h6>
            <small>Total Members</small>
          </li>
        </ul>
      </div>

      <div className="tab-content p-l-0 p-r-0">
        <nav id="left-sidebar-nav" className="sidebar-nav">
          <ul id="main-menu" className="metismenu">
            <li className={(url === "/dashboard" ? " active" : "")}>
              <a href="/dashboard">
                <img src={overview_icon} alt="Overview-icon" />
                <span>Overview</span>
              </a>
            </li>
            <li className={(url === "/Project" ? " active" : "")}>
              <a href="/Project">
                <img src={project_icon} alt="Projects-icon" />
                <span>Projects</span>
              </a>
            </li>
            <li className={(url === "/Memberships" ? " active" : "")}>
              <a href="#">
                <img src={member_icon} alt="Memberships-icon" />
                <span>Memberships</span>
              </a>
            </li>
            <li>
              <a href="#">
                <img src={explore_icon} alt="Explore-icon" />
                <span>Live Projects</span>
              </a>
            </li>
            <li>
              <a href="#">
                <img src={wallet_icon} alt="Wallet-icon" /> <span>Wallet</span>
              </a>
            </li>
            <li className="sidenav-footer">
              <Link className="getstarted scrollto nav-link" to="/login" onClick={() => {
                dispatch(logoutUser());
              }}>
                <img src={log_icon} alt="Log-icon" /> <span>Logout</span>
              </Link>
              {/* <a href="#" onClick={() => {
                dispatch(logoutUser());
              }}>
                <img src={log_icon} alt="Log-icon" /> <span>Logout</span>
              </a> */}
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}
