import React, { useState, useEffect } from "react";
import "../css/main.css";
import axios from "axios";
import { generalValidation, generalOptionalValidation } from "./validations/createProjectValidation";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../../redux/action/user.action";
import { CreateNewProject } from "../../../redux/action/user.action";

import createIndividual from "../../../assets/images/createIndividual-icon.png";
import addIndividual from "../../../assets/images/addIndividual-icon.png";
import pojectIndividual from "../../../assets/images/pojectIndividual-icon.png";
import joinIndividual from "../../../assets/images/joinIndividual-icon.png";


const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function ProjectButtons({ getProjects, loadData, status }) {
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [userID, setUserID] = useState('');
  const [userType, setUserType] = useState(0);
  const getToken = JSON.parse(localStorage.getItem('currentUser'));
  const Join_as = [
    "--Select--",
    "Admin",
    "User",
    "Owner"
  ];

  const [generalModalDismiss, setGeneralModalDismiss] = useState(false)
  const [optionalModalDismiss, setOptionalModalDismiss] = useState(false)
  const [checkError, setCheckError] = useState(false)
  const [errors, setErrors] = useState({});
  const [Inputs, setInputs] = useState({
    project_type: "",
    project_name: "",
    project_location: "",
    project_start_date: "",
    project_end_date: "",
    project_join_as: "",
    project_type_of_giving: "",
    project_target_amount: "",
    project_id: "",
    project_pin: "",
    project_image: "",
    project_doc: "",
    project_description: "",
    project_visibility: "",
  });

  const HandleInputs = (e) => {
    var { name, value } = e.target;
    setInputs({ ...Inputs, [name]: value });
    if (Object.keys(errors).length) {
      //   setErrors(generalValidation(Inputs));
      setCheckError(true)
    }
  };
  //Upload Image function
  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const handleUploadImg = (e) => {
    setImage(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    setImageName(e.target.files[0].name)
    setInputs({ ...Inputs, [e.target.name]: e.target.files[0] });
  }
  // Upload Document function
  const [docFile, setDocFile] = useState('');
  const [docName, setDocName] = useState('');
  const [docUrl, setDocUrl] = useState('');
  const [docUpload, setDocUpload] = useState(false);
  const handleUploadDoc = (e) => {
    let selectedFile = e.target.files[0]
    setDocFile(e.target.files[0]);
    if (selectedFile) {
      setDocUrl(URL.createObjectURL(e.target.files[0]));
      setDocUpload(true)
    }
    setInputs({ ...Inputs, [e.target.name]: e.target.files[0] });
    setDocName(e.target.files[0].name)
  }

  // Set Attributes for modal button
  function setAttributes(element, attributes) {
    Object.keys(attributes).forEach(attr => {
      element.setAttribute(attr, attributes[attr]);
    });
  }

  // Select type for Create New Project
  const projectTypeAttr = {
    'data-target': '#GeneralDetails-Modal',
  };

  const [error, setError] = useState(false);
  const createProjectType = (e) => {
    e.preventDefault();
    if (Inputs.project_type) {
      setAttributes(e.target, projectTypeAttr);
      setError(false)
    }
    else {
      setError(true)
    }
  }

  //General Details modal function
  const [optionalOpen, setOptionalOpen] = useState(false);

  const generalDetailsAttr = {
    'data-target': '#GeneralDetailsoptional-Modal',
  };

  const generalDetails = (e) => {
    e.preventDefault()
    setErrors(generalValidation(Inputs));
    // console.log(Object.keys(errors).length)
    if (Object.keys(errors).length === 0 && Inputs.project_name && Inputs.project_location && Inputs.project_start_date && Inputs.project_end_date && Inputs.project_join_as && Inputs.project_type_of_giving && Inputs.project_target_amount) {
      setAttributes(e.target, generalDetailsAttr);
      setOptionalOpen(true);
    }
    setTimeout(() => {
      e.target.removeAttribute('data-target')
    }, 1000)
  }

  //General Details Optional modal Attributes
  const saveAsDraftAttr = {
    'data-target': '#Saveasdraft-Modal',
  };
  const generalDetailsOptionalAttr = {
    'data-target': '#Congratulations-Modal',
  };

  // Create Project function
  const CreateProject = (e) => {
    e.preventDefault()
    setErrors(generalOptionalValidation(Inputs));
    if (Object.keys(errors).length === 0 && Inputs.project_id && Inputs.project_description && Inputs.project_visibility) {
      setOptionalOpen(false);
      var form = new FormData();
      form.append("project_type", Inputs.project_type)
      form.append("project_name", Inputs.project_name);
      form.append("project_location", Inputs.project_location);
      form.append("project_start_date", Inputs.project_start_date);
      form.append("project_end_date", Inputs.project_end_date);
      form.append("project_join_as", Inputs.project_join_as);
      form.append("project_type_of_giving", Inputs.project_type_of_giving);
      form.append("project_target_amount", Inputs.project_target_amount);
      form.append("project_id", Inputs.project_id);
      form.append("project_pin", Inputs.project_pin);
      form.append("project_image", Inputs.project_image);
      form.append("project_doc", Inputs.project_doc);
      form.append("project_description", Inputs.project_description);
      form.append("project_visibility", Inputs.project_visibility);
      form.append("user_id", userID);
      form.append("user_type", userType);
      if (e.target.name === "saveAsDraft") {
        console.log('sdfsdfs')
        setAttributes(e.target, saveAsDraftAttr);
        form.append("project_status", "Draft");
        setTimeout(() => {
          e.target.removeAttribute('data-target')
        }, 1000)
      }
      else {
        setAttributes(e.target, generalDetailsOptionalAttr);
        form.append("project_status", "Active");
        setTimeout(() => {
          e.target.removeAttribute('data-target')
        }, 1000)
      }

      axios.post(`https://api.emembr.com/api/project`, form, {
        headers: {
          "Authorization": "Bearer " + token
        }
      })
        .then(function (response) {
          console.log(response)
            if (status && status !== 'All') {
              dispatch(getAllProjects(status))
            }
            else {
              dispatch(getAllProjects())
            }
          
        })
        .catch(function (error) {
          console.log(error);
        })
    }

  };

  // Filter Projects according to status


  useEffect(() => {
    if (!token && getToken) {
      setToken(getToken.data.token)
    }
    if (!userID && getToken) {
      setUserID(getToken.data.user_id)
    }
    if (!userType && getToken) {
      setUserType(getToken.data.user_type)
    }

    //Function for modal window dismiss
    if (Object.keys(errors).length === 0 && Inputs.project_name && Inputs.project_location && Inputs.project_start_date && Inputs.project_end_date && Inputs.project_join_as && Inputs.project_type_of_giving && Inputs.project_target_amount) {
      setGeneralModalDismiss(true)
    }
    else {
      setGeneralModalDismiss(false)
    }
    if (Object.keys(errors).length === 0 && Inputs.project_id && Inputs.project_description && Inputs.project_visibility) {
      setOptionalModalDismiss(true)
    }
    else {
      setOptionalModalDismiss(false)
    }

    // Error function
    // console.log(Object.keys(errors).length, Inputs, errors)
    if (Object.keys(errors).length && checkError) {
      if (optionalOpen) {
        setErrors(generalOptionalValidation(Inputs))
      }
      else {
        setErrors(generalValidation(Inputs));
      }
      setCheckError(false)
    }
  }, [token, getToken, userID, Inputs, checkError, optionalOpen, userType])
  return (
    <>
      <div className="col-lg-12">
        <div className="card">
          <div className="body">
            <div className="Project-action clearfix">
              <div className="pull-left Project-dropdown">
                <button
                  className="btn  btn-sm dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {status ? status : 'All'} Projects
                </button>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="javascript:void(0);">
                    <label className="fancy-radio">
                      <input type="radio" name="project" value="All" defaultChecked="checked" onChange={getProjects} /> <i></i>{" "}
                      All
                    </label>
                  </a>
                  <a className="dropdown-item" href="javascript:void(0);">
                    <label className="fancy-radio">
                      {" "}
                      <input type="radio" name="project" value="Active" onChange={getProjects} />{" "}
                      <i></i> Active
                    </label>
                  </a>
                  <a className="dropdown-item" href="javascript:void(0);">
                    <label className="fancy-radio">
                      {" "}
                      <input type="radio" name="project" value="Completed" onChange={getProjects} />{" "}
                      <i></i> Completed
                    </label>
                  </a>
                  <a className="dropdown-item" href="javascript:void(0);">
                    <label className="fancy-radio">
                      {" "}
                      <input type="radio" name="project" value="Closed" onChange={getProjects} />{" "}
                      <i></i> Closed
                    </label>
                  </a>
                  <a className="dropdown-item" href="javascript:void(0);">
                    <label className="fancy-radio">
                      {" "}
                      <input type="radio" name="project" value="Draft" onChange={getProjects} />{" "}
                      <i></i> Draft
                    </label>
                  </a>
                </div>
              </div>
              <div className="pull-right ml-auto">
                <div className="btn-group ProjectRight-btns">
                  <button
                    type="button"
                    className="btn pbtn Makebtn"
                    data-toggle="modal"
                    data-target="#addcontact"
                  >
                    <i className="icon-plus"></i> Make a Payment
                  </button>
                  <button
                    type="button"
                    className="btn pbtn Createbtn"
                    data-toggle="modal"
                    data-target="#Createnew-project"
                  >
                    <i className="icon-plus"></i> Create New Project
                  </button>
                  <button
                    type="button"
                    className="btn pbtn Joinbtn"
                    data-toggle="modal"
                    data-target="#PrivateProject-Modal"
                  >
                    <i className="icon-plus"></i> Join Private Project
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errors.length}
      {/* Add New Task */}

      <div
        className="modal fade ProjectModal"
        id="Createnew-project"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog modal-sm  modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title"> Create New Project</h4>
              <button type="button" className="close" data-dismiss="modal">
                <img src="assets/images/close-circle.png" alt="Log-icon" />
              </button>
            </div>
            <form >
              <div className="modal-body">
                <div className="row clearfix">
                  <div className="col-12">
                    <div className="Project-Create">
                      {error ? <div className="pb-2" style={{ color: "red" }}>Please choose any one option.</div> : ''}
                      <label className="fancy-radio">
                        <input type="radio" name="project_type" value="Individual" onChange={HandleInputs} /> <i></i>{" "}
                        Create Individual Project
                      </label>
                      <label className="fancy-radio">
                        <input type="radio" name="project_type" value="Group" onChange={HandleInputs} /> <i></i>{" "}
                        Create Group Project
                      </label>
                      <label className="fancy-radio">
                        <input type="radio" name="project_type" value="Business" onChange={HandleInputs} />{" "}
                        <i></i> Create Business Group
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <a
                  href="#GeneralDetails-Modal"
                  className=" btn btn-primary"
                  data-toggle="modal"
                  data-dismiss="modal"
                >
                  Next{" "}
                </a> */}
                <input type="submit" className="btn btn-primary" value="Next" onClick={createProjectType} data-toggle="modal" data-dismiss={Inputs.project_type ? 'modal' : ''} />
              </div>
            </form>
          </div>
        </div>
      </div>
      {/*Private Project modal */}
      <div
        className="modal fade ProjectModal"
        id="PrivateProject-Modal"
        tabIndex="-1"
        role="dialog"
      >

        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="defaultModalLabel">
                Private Project Details{" "}
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                <img src="assets/images/close-circle.png" alt="Log-icon" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-12">
                  <div className="form-group">
                    <label>Project ID:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Task no."
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Project ID:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Job title"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>Joining Role:</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="User"
                    />
                  </div>
                  <a className="project-privacy" href="#">
                    Learn more about project privacy
                  </a>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <a
                href="#Congratulations-Modal"
                className=" btn btn-primary"
                data-toggle="modal"
                data-dismiss="modal"
              >
                Join Project{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* General Details-Modal */}
      <div
        className="modal fade ProjectModal"
        id="GeneralDetails-Modal"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="defaultModalLabel">
                General Details
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                <img src="assets/images/close-circle.png" alt="Log-icon" />
              </button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row clearfix">
                  <div className="col-6">
                    <div className="form-group">
                      <label>Project Name:</label>
                      <input
                        type="text"
                        name="project_name"
                        className="form-control"
                        placeholder="Wakefield Project"
                        value={Inputs.project_name || ''}
                        onChange={HandleInputs}
                      />
                    </div>
                    {errors.project_name && <p style={{ color: "red" }}>{errors.project_name}</p>}
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Location:</label>
                      <input
                        type="text"
                        name="project_location"
                        className="form-control"
                        placeholder="United Kingdom"
                        value={Inputs.project_location || ''}
                        onChange={HandleInputs}
                      />
                    </div>
                    {errors.project_location && <p style={{ color: "red" }}>{errors.project_location}</p>}
                  </div>
                  <div className="col-12 m-b-10">
                    <label>Project Dates:</label>
                    <div className="row">
                      <div className="col">
                        <div className="input-daterange input-group"
                          data-provide="datepicker"
                        >
                          <input
                            type="date"
                            className="form-control"
                            //  name="start"
                            name="project_start_date"
                            placeholder="Start Date"
                            value={Inputs.project_start_date || ''}
                            onChange={HandleInputs}
                          />
                        </div>
                        {errors.project_start_date && <p style={{ color: "red" }}>{errors.project_start_date}</p>}
                      </div>
                      <div className="col">
                        <div className="input-daterange input-group" data-provide="datepicker">
                          <input
                            type="date"
                            className="form-control"
                            //  name="end"
                            name="project_end_date"
                            placeholder="End Date/ Reocurrance"
                            value={Inputs.project_end_date || ''}
                            onChange={HandleInputs}
                          />
                        </div>
                        {errors.project_end_date && <p style={{ color: "red" }}>{errors.project_end_date}</p>}
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <label>Join As:</label>
                    <select
                      className="form-control"
                      id="project_join_as"
                      name="project_join_as"
                      required
                      value={Inputs.project_join_as || ''}
                      onChange={HandleInputs}
                    >
                      {Join_as.map((gen, key) => {
                        return (
                          <option key={key} value={gen}>
                            {gen}
                          </option>
                        );
                      })}
                    </select>

                    {errors.project_join_as && <p style={{ color: "red" }}>{errors.project_join_as}</p>}
                  </div>
                  <div className="col-6">
                    <label>Choose Type of Giving:</label>
                    <select
                      className="form-control show-tick m-b-10"
                      name="project_type_of_giving"
                      value={Inputs.project_type_of_giving || ''}
                      onChange={HandleInputs}
                    >
                      <option value="">Select Team</option>
                      <option value="John Smith">John Smith</option>
                      <option value="Hossein Shams">Hossein Shams</option>
                      <option value="Maryam Amiri">Maryam Amiri</option>
                      <option value="Tim Hank">Tim Hank</option>
                      <option value="Gary Camara">Gary Camara</option>
                    </select>
                    {errors.project_type_of_giving && <p style={{ color: "red" }}>{errors.project_type_of_giving}</p>}
                  </div>
                  <div className="col-6">
                    <div className="form-group">
                      <label>Target Amount :</label>
                      <input
                        type="text"
                        name="project_target_amount"
                        className="form-control"
                        placeholder="Target Amount"
                        value={Inputs.project_target_amount || ''}
                        onChange={HandleInputs}
                      />
                    </div>
                    {errors.project_target_amount && <p style={{ color: "red" }}>{errors.project_target_amount}</p>}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                {/* <a
                  href="#GeneralDetailsoptional-Modal"
                  className=" btn btn-primary"
                  data-toggle="modal"
                  data-dismiss="modal"
                >
                  Next{" "}
                </a> */}
                <input type="submit" className="btn btn-primary" value="Next" onClick={generalDetails} data-toggle="modal" data-dismiss={generalModalDismiss ? 'modal' : ''} />
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* General Details optional-Modal */}
      <div
        className="modal fade ProjectModal"
        id="GeneralDetailsoptional-Modal"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog modal-lg  modal-dialog-centered modal-dialog-scrollable"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="defaultModalLabel">
                General Details
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                <img src="assets/images/close-circle.png" alt="Log-icon" />
              </button>
            </div>
            <div className="modal-body">
              <div className="row clearfix">
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Create Project ID:
                    </label>
                    <input
                      type="text"
                      name="project_id"
                      className="form-control"
                      placeholder=""
                      value={Inputs.project_id || ''}
                      onChange={HandleInputs}
                    />
                  </div>
                  {errors.project_id && <p style={{ color: "red" }}>{errors.project_id}</p>}
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>
                      Create Project PIN: <small>optional</small>
                    </label>
                    <input
                      type="text"
                      name="project_pin"
                      className="form-control"
                      placeholder="United Kingdom"
                      value={Inputs.project_pin || ''}
                      onChange={HandleInputs}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label>
                    Add Images: <small>optional</small>
                  </label>
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="project_image"
                        className="custom-file-input"
                        id="inputGroupFile01"
                        onChange={handleUploadImg}
                        accept="image/x-png,image/gif,image/jpeg"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile01"
                      >
                        {imageName ? imageName : "JPEG, PNG / 10MB file limit"}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <label>
                    Add Documentation: <small>optional</small>
                  </label>
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="project_doc"
                        className="custom-file-input"
                        id="inputGroupFile01"
                        onChange={handleUploadDoc}
                        accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile01"
                      >
                        {docName ? docName : 'Upload'}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-12 form-group">
                  <label>Add Description:</label>
                  <textarea
                    className="form-control"
                    name="project_description"
                    rows="5"
                    cols="30"
                    required
                    placeholder="Add project description here"
                    value={Inputs.project_description || ''}
                    onChange={HandleInputs}
                  ></textarea>
                  {errors.project_description && <p style={{ color: "red" }}>{errors.project_description}</p>}
                </div>
                <div className="col-12">
                  <div className="Project-Create">
                    <label className="fancy-radio">
                      <input type="radio" name="project_visibility" value="visible" onChange={HandleInputs} /> <i></i>{" "}
                      Visible
                    </label>
                    <p>
                      {" "}
                      Go to create new project and create new membership.Joined
                      or created proejcts by you will appear here.
                    </p>
                    <label className="fancy-radio">
                      {" "}
                      <input type="radio" name="project_visibility" value="hide" onChange={HandleInputs} />{" "}
                      <i></i> Hide
                    </label>
                    <p>
                      This project will not be visible on the explore projects
                      page. Members can join this project with the Project ID
                      number and Project Access Code.
                    </p>
                    {errors.project_visibility && <p style={{ color: "red" }}>{errors.project_visibility}</p>}
                  </div>
                  <a className="project-privacy" href="#">
                    Learn more about project privacy
                  </a>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {/* <a
                href="#Saveasdraft-Modal"
                className="btn btn-primary"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={CreateProject}
              >
                Save as draft{" "}
              </a>
              <a
                href="#Congratulations-Modal"
                className="btn btn-wthbg"
                data-toggle="modal"
                data-dismiss="modal"
                onClick={CreateProject}
              >
                Make Project live
              </a> */}
              <input type="submit" name="saveAsDraft" className="btn btn-primary" value="Save as Draft" onClick={CreateProject} data-toggle="modal" data-dismiss={optionalModalDismiss ? 'modal' : ''} />
              <input type="submit" className="btn btn-wthbg" value="Make Project live" onClick={CreateProject} data-toggle="modal" data-dismiss={optionalModalDismiss ? 'modal' : ''} />
            </div>
          </div>
        </div>
      </div>
      {/* Congratulations-Modal */}
      <div
        className="modal fade ProjectModal"
        id="Congratulations-Modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog  modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="defaultModalLabel">
                General Details{" "}
              </h4>
              <button type="button" className="close" data-dismiss="modal">
                <img src="assets/images/close-circle.png" alt="Log-icon" />
              </button>
            </div>
            <div className="modal-body">
              <h2>Congratulations!</h2>
              <p>Your project is now live.</p>
              <br />
              <p>
                You can now invite members to join this project by providing
                them with the details below:
              </p>
              <p>
                Project Name: <span>{Inputs.project_name}</span>
              </p>
              <p>
                Project ID: <span>{Inputs.project_id}</span>
              </p>
              <p>
                Project PIN: <span>{Inputs.project_pin}</span>
              </p>
            </div>
            <div className="modal-footer">
              <a href="/dashboard" className="btn btn-primary">
                Go to dashboard{" "}
              </a>
              {/* <a href="individualMember-invite.html" className=" btn btn-wthbg"> */}
              <a href="#" className=" btn btn-wthbg">
                Send join invites{" "}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Saveasdraft-Modal */}
      <div
        className="modal fade ProjectModal"
        id="Saveasdraft-Modal"
        tabIndex="-1"
        role="dialog"
      >
        <div
          className="modal-dialog  modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body">
              <img
                src="assets/images/individualdraft-saved.png"
                alt="individualdraft-saved"
                className="img-responsive"
              />
              <h5>
                Your project has been
                <br /> saved as a draft.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
