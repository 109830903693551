import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function VerifySuccess() {
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <p className="verify-email">
                            <i className="bi bi-check-circle"></i>
                            <span className="d-block">Thank You!
                            Your Email address verify successfully.</span>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}