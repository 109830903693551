import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function AuthUser() {
    const navigate = useNavigate()
    const userDetails =  JSON.parse(localStorage.getItem('currentUser'));
    setTimeout(()=>{
        if(!userDetails){
            navigate('/login')
            return;
        }
    }, 1000)
    

    const getToken = () => {
        const userDetails =  JSON.parse(localStorage.getItem('currentUser'));
        const userToken = userDetails.data.token;
        return userToken;
    }

    const getUser = () => {
        const userDetails =  JSON.parse(localStorage.getItem('currentUser'));
        const user = userDetails.data.first_name +" "+ userDetails.data.last_name;
        return user;
    }

    const getUserID = () => {
        const userDetails =  JSON.parse(localStorage.getItem('currentUser'));
        const userID = userDetails.data.user_id;
        return userID;
    }
    const getUserType = () => {
        const userDetails =  JSON.parse(localStorage.getItem('currentUser'));
        const userType = userDetails.data.user_type;
        return userType;
    }
    
    const [token, setToken] = useState(getToken());
    const [user, setUser] = useState(getUser());
    const [userID, setUserID] = useState(getUserID());
    const [userType, setUserType]= useState(getUserType());
    
    return {
        token,
        user,
        userID,
        userType
    }
}