import axios from "axios";
import { toast } from "react-toastify";

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const registerNewUser = (user) => (dispatch) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('first_name', user.firstname);
  data.append('last_name', user.lastname);
  data.append('password', user.password);
  data.append('c_password', user.repassword);
  data.append('address', user.address);
  data.append('city', user.city);
  data.append('post_code', user.postcode);
  data.append('gender', user.gender);
  data.append('phone_number', user.phone);
  data.append('dob', user.dob);
  data.append('status', user.status);
  data.append('type', user.type);
  data.append('group_email', user.group_email || "");
  data.append('group_name', user.group_name || "");
  data.append('group_pin', user.group_pin || "");
  data.append('group_type', user.group_type || "");
  data.append('group_duration', user.group_duration || "");
  data.append('group_city', user.group_city || "");
  data.append('group_phone_number', user.group_phone_number || "");
  data.append('business_name', user.business_name || "");
  data.append('business_email', user.business_email || "");
  data.append('business_number', user.business_number || "");
  data.append('business_phone_number', user.business_phone_number || "");
  data.append('business_city', user.business_city || "");
  data.append('business_address', user.business_address || "");
  data.append('business_postcode', user.business_postcode || "");

  dispatch({ type: "USER_REGISTER_REQUEST" });

  axios.post(BASE_URL + "/register", data, {
    headers: {
      "Content-Type": "Multipart/form-data"
    }
  })
    .then((res) => {
      dispatch({ type: "USER_REGISTER_SUCCESS", payload:res });
      toast.success("Success");
      console.log(res);
    })
    .catch((err) => {
      dispatch({ type: "USER_REGISTER_FAILED", payload: err });
      console.log(err);
    });
};

export const loginUser = (user) => (dispatch) => {
  dispatch({ type: "USER_LOGIN_REQUEST" });
  // console.log("login", user)
  axios
    .post(BASE_URL + "/login", user)
    .then((res) => {
      console.log(res.data)
      if (res.status === 200) {
        window.location.href = "/dashboard";
        setTimeout(() => {
          dispatch({ type: "USER_LOGIN_SUCCESS" });
        }, 500)
        localStorage.setItem("currentUser", JSON.stringify(res.data));
        return
      }
    })

    .catch((err) => {
      dispatch({
        type: "USER_LOGIN_FAILED",
        payload: err,
      });
      console.log(err);
    });
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("cartItems");
  dispatch({ type: "USER_LOGOUT" });
  // window.location.href = "/login";
};

// Update User function
export const updateUser = (userid, updateUser) => (dispatch) => {
  // console.log(userid, updateUser)
  dispatch({
    type: "USER_UPDATE_REQUEST",
  });

  const data = new URLSearchParams();
  data.append('type', updateUser.type);
  if (updateUser) {
    if (updateUser.type === 2) {
      data.append('group_email', updateUser.group_email || "");
      data.append('group_name', updateUser.group_name || "");
      data.append('group_pin', updateUser.group_pin || "");
      data.append('group_type', updateUser.group_type || "");
      data.append('group_duration', updateUser.group_duration || "");
      data.append('group_city', updateUser.group_city || "");
      data.append('group_phone_number', updateUser.group_phone_number || "");
    }
    if (updateUser.type === 3) {
      data.append('business_name', updateUser.business_name || "");
      data.append('business_email', updateUser.business_email || "");
      data.append('business_number', updateUser.business_number || "");
      data.append('business_phone_number', updateUser.business_phone_number || "");
      data.append('business_city', updateUser.business_city || "");
      data.append('business_address', updateUser.business_address || "");
      data.append('business_postcode', updateUser.business_postcode || "");
    }
  }


  var update = {
    method: "put",
    url: BASE_URL + `/user/${userid}`,
    data:data,
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": "application/x-www-form-urlencoded"
    },
  };

  /* axios.put(`${BASE_URL}/user/${userid}`, data, {
    headers: {
      "Authorization": "Bearer " + token,
      'Content-type': 'application/x-www-form-urlencoded',
    }
  })*/
  axios(update)
    .then((res) => {
      toast.success("Data has been updated Successfully.");
      dispatch({
        type: "USER_UPDATE_SUCCESS",
      });
      // window.location.reload();
    })
    .catch((err) => {
      dispatch({
        type: "USER_UPDATE_FAILED",
        payload: err,
      });
      console.log(err);
    });
};

export const getAllUsers = () => (dispatch) => {
  dispatch({ type: "GET_ALLUSERS_REQUEST" });

  axios
    .get("/users")
    .then((res) => {
      dispatch({ type: "GET_ALLUSERS_SUCCESS", payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: "GET_ALLUSERS_FAILED", payload: err });
    });
};

export const deleteUser = (userid) => (dispatch) => {
  dispatch({
    type: "DELETE_USER_REQUEST",
  });

  axios
    .delete(`/users/${userid}`)
    .then((res) => {
      dispatch({
        type: "DELETE_USER_SUCCESS",
        payload: res.data,
      });
      alert("User deleted successfully");
      window.location.reload();
    })
    .catch((err) => {
      dispatch({
        type: "DELETE_USER_FAILED",
        payload: err,
      });
    });
};

export const forgotPassword = (email) => (dispatch) => {
  dispatch({ type: "FORGOT_PASSWORD_REQUEST" });
  console.log(email);

  axios
    .post(BASE_URL + "/password/forgot-password", { email })
    .then((res) => {
      dispatch({ type: "FORGOT_PASSWORD_SUCCESS" });
      toast.success("Success");
      console.log(res);
    })
    .catch((err) => {
      dispatch({ type: "FORGOT_PASSWORD_FAILED", payload: err });
      toast.error("Error");
      console.log(err);
    });
};

export const personaldetails = (obj) => (dispatch) => {
  // console.log(obj);
  dispatch({ type: "PERSONAL_DETAILS", payload: obj });
};

export const addressdetails = (addressdata) => (dispatch) => {
  // console.log(addressdata);
  dispatch({ type: "ADDRESS_DETAILS", payload: addressdata });
};

export const groupdetails = (groupData) => (dispatch) => {
  // console.log(groupData);
  dispatch({ type: "GROUP_DETAILS", payload: groupData });
};

export const businessdetails = (BusinessData) => (dispatch) => {
  // console.log(BusinessData);
  dispatch({ type: "BUSINESS_DETAILS", payload: BusinessData });
};

export const CreateNewProject = (data) => (dispatch) => {
  dispatch({ type: "CREATE_NEW_PROJECT" });

  axios
    .post("https://intheai.in/api/product/add", data)
    .then((res) => {
      dispatch({ type: "PROJECT_CREATED" });
      toast.success("Success");
      console.log(res);
    })
    .catch((err) => {
      dispatch({ type: "PROJECT_CREATION_FAILED", payload: err });
      console.log(err);
    });
};

const tokenData = JSON.parse(localStorage.getItem('currentUser'));
let token;
let userID;
let userType;
if (tokenData) {
  token = tokenData.data.token;
  userID = tokenData.data.user_id;
  userType = tokenData.data.user_type;
}


let api;
export const getAllProjects = (status, pageNumber, user_type) => (dispatch) => {
  console.log(status, pageNumber, user_type)
  if (pageNumber) {
    var pageNumberVal = `&page=${pageNumber}`
  }
  else {
    pageNumberVal = '';
  }
  if(user_type && userType !== user_type){
    userType = user_type;
  }
  console.log(userType)
  if (status && status !== 'All') {
    api = `/projectlist?&status=${status}&paginate=1&perpage=10&user_id=${userID}&user_type=${userType}${pageNumberVal}`;
  }
  else {
    api = `/projectlist?&paginate=1&perpage=10&user_id=${userID}&user_type=${userType}${pageNumberVal}`;
  }
  // console.log(api)
  var config = {
    method: "get",
    url: BASE_URL + api,
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + token
    },
  };
  dispatch({ type: "GET_ALL_PROJECTS" });
  // https://api.emembr.com/api/projectlist?&status=Draft&paginate=1&perpage=1&page=1
  axios(config)
    .then((res) => {
      // console.log(res.data)
      dispatch({ type: "GET_ALLPROJECTS_SUCCESS", payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: "GET_ALLPROJECTS_FAILED", payload: err });
    });
};

export const getUserType = (userid) => (dispatch) => {
  console.log('User Id Action = '+userid)
  var config = {
    method: "get",
    url: BASE_URL + `/accounttype?user_id=${userid}`,
    headers: {
      "Accept": "application/json",
      "Authorization": "Bearer " + token
    },
  };
  dispatch({ type: "GET_USERTYPES" });
  axios(config)
    .then((res) => {
      dispatch({ type: "GET_USERTYPE_SUCCESS", payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: "GET_USERTYPE_FAILED", payload: err });
    });
}
export const updateUserType = (updateUserData) => (dispatch) => {
  // console.log('User Id Updated = '+ updateUserData)
  const updateData = new URLSearchParams();
  updateData.append('user_type', updateUserData.user_type)
  const updateUserProfile = {
    method: "put",
    url: BASE_URL + `/accounttype/${userID}`,
    data: updateData,
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": "application/x-www-form-urlencoded"
    },
  };
  dispatch({ type: "UPDATE_USERTYPES" });
  axios(updateUserProfile)
    .then((res) => {
      dispatch({ type: "UPDATE_USERTYPE_SUCCESS", payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: "UPDATE_USERTYPE_FAILED", payload: err });
    });
}
