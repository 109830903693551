import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import AuthUser from "../../Auth/AuthUser";
import { getAllProjects, getUserType, updateUser, updateUserType } from "../../../redux/action/user.action";
import { businessDetailsValidation, groupDetailsValidation } from "./validations/createProjectValidation";
import userIcon from "../../../assets/images/user-icon.png";
// import user from "../../../assets/images/user-icon.png";
import "../css/main.css";
import LoadingSpinner from "../../LoadingSpinner";

export default function Navbar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserTypes = useSelector((state) => state.getUserTypeReducer);
  const updateLoading = useSelector((state) => state.updateReducer.loading);
  const error = useSelector((state) => state.updateReducer.error);
  const switchProfileLoading = useSelector((state) => state.updateUserTypeReducer.loading);

  const { user, userType, userID } = AuthUser();
  const [searchInput, setSearchInput] = useState("");
  const [userName, setUserName] = useState('');
  const [createLink, setCreateLink] = useState('');
  const [groupdetails, setGroupdetails] = useState({});
  const [businessdetails, setBusinessdetails] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [loadUserType, setLoadUserType] = useState(false);
  const [getUser, setGetUser] = useState(false);

  /* Modal Close States */
  const handleClose = () => {
    setShow(false);
    setBusinessModal(false);
    setIndividualModal(false);
  }
  /* Individual Details Modal functionality */
  const [individualModal, setIndividualModal] = useState(false);
  const handleIndividualShow = () => setIndividualModal(true);
  const confirmIndividual = () => {
    const Individual = {
      type: 1
    }
    dispatch(updateUser(userID, Individual));
  }
  /* Group Details Modal functionality */
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleGroupDetails = (e) => {
    setGroupdetails({ ...groupdetails, [e.target.name]: e.target.value })
  }
  const submitGroupDetails = () => {
    setErrors(groupDetailsValidation(groupdetails));
    const Group = {
      group_email: groupdetails.groupEmail,
      group_name: groupdetails.groupName,
      group_pin: groupdetails.pin,
      group_type: groupdetails.type,
      group_duration: groupdetails.duration,
      group_city: groupdetails.city,
      group_phone_number: groupdetails.groupPhone,
      type: 2
    };
    dispatch(updateUser(userID, Group));
    setGetUser(true)
  }
  /* Business Details Modal functionality */
  const handleBusinessDetails = (e) => {
    setBusinessdetails({ ...businessdetails, [e.target.name]: e.target.value })
  }
  const submitBusinessdetails = () => {
    setErrors(businessDetailsValidation(businessdetails));
    const Business = {
      business_name: businessdetails.business_name,
      business_email: businessdetails.business_email,
      business_number: businessdetails.business_number,
      business_phone_number: businessdetails.business_phonenumber,
      business_city: businessdetails.business_city,
      business_address: businessdetails.business_address,
      business_postcode: businessdetails.business_postcode,
      type: 3
    };
    dispatch(updateUser(userID, Business));
    setGetUser(true)
  }

  /* Switch Profile functionality */
  const [switchProfile, setSwitchProfile] = useState(false);
  const [profileType, setProfileType] = useState(0);

  const handleIndividualProfile = () => {
    setProfileType(1)
    setSwitchProfile(true)
    setIsLoading(true);
    const updateUserProfile = {
      user_type: 1
    }
    dispatch(updateUserType(updateUserProfile));
  }
  const handleGroupProfile = () => {
    setProfileType(2)
    setSwitchProfile(true)
    setIsLoading(true);
    const updateUserProfile = {
      user_type: 2
    }
    dispatch(updateUserType(updateUserProfile));
  }
  const handleBusinessProfile = () => {
    setProfileType(3)
    setSwitchProfile(true)
    setIsLoading(true);
    const updateUserProfile = {
      user_type: 3
    }
    dispatch(updateUserType(updateUserProfile));
  }

  const [businessModal, setBusinessModal] = useState(false);
  const handleBusinessShow = () => setBusinessModal(true);

  const [individualLink, setIndividualLink] = useState(true);
  const [groupLink, setGroupLink] = useState(true);
  const [businessLink, setBusinessLink] = useState(true);
  const [checkLink, setCheckLink] = useState(true);


  useEffect(() => {
    /* Set User Name */
    if (!userName) {
      setUserName(user)
    }
    /* Set Create Links */
    if (!createLink && !loadUserType || updateLoading && getUser) {
      dispatch(getUserType(userID))
      setLoadUserType(true)
      setGetUser(false)
      setCheckLink(true)
    }
    if (getUserTypes && loadUserType) {
      setCreateLink(getUserTypes.userTypeData)
    }
    // console.log(getUserTypes,createLink, checkLink)
    /* Close function for Individual, Group and Business modals */
    if (!updateLoading) {
      setShow(false);
      setBusinessModal(false);
      setIndividualModal(false);
    }
    // if (error) {
    //   const toastError = error.business_email;
    //   console.log("Error = "+ toastError)
    //   toast.error(toastError);
    // }
    /* Switch Profile function */
    if (!switchProfileLoading && switchProfile) {
      console.log('False = ' + switchProfileLoading)
      const profile = JSON.parse(localStorage.getItem('currentUser'))
      profile.data.type = profileType;
      profile.data.user_type = profileType;
      localStorage.setItem('currentUser', JSON.stringify(profile));
      setSwitchProfile(false)
      window.location.reload();
    }
    /* Set Create links function */
    if (createLink && checkLink) {
      let arr = createLink.user;
      arr.forEach((user, index) => {
        if (user.user_type === 1) {
          setIndividualLink(false)
        }
        else if (user.user_type === 2) {
          setGroupLink(false)
        }
        else {
          setBusinessLink(false)
        }
      });
      setCheckLink(false);
    }

  }, [userName, userType, createLink, userID, getUserTypes, loadUserType, switchProfileLoading, switchProfile, profileType, updateLoading])

  return (
    <>
      <div className="navbar-btn">
        <button type="button" className="btn-toggle-offcanvas">
          <i className="lnr lnr-menu fa fa-bars"></i>
        </button>
      </div>
      <div className="navbar-brand">
        <a href="/" style={{ padding: 0 }}>
          <img src={logo} alt="Lucid Logo" className="img-responsive logo" />
        </a>
      </div>
      <div className="navbar-right">
        <div className="nav-control">
          <div className="hamburger btn-toggle-fullwidth">
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
        <div id="navbar-menu">
          <ul className="nav navbar-nav">
            <li style={{ marginLeft: "4px" }}>
              <a
                href="javascript:void(0);"
                className="icon-menu d-none d-sm-block d-md-none d-lg-block"
              >
                <form id="navbar-search" className="navbar-form search-form">
                  <input
                    value=""
                    className="form-control"
                    placeholder="Search by member name or order number"
                    type="text"
                  />
                  <button type="button" className="btn btn-default">
                    <i className="icon-magnifier"></i>
                  </button>
                </form>
              </a>
            </li>
            <li style={{ marginLeft: "4px" }}>
              <a
                href="app-calendar.html"
                className="icon-menu d-none d-sm-block d-md-none d-lg-block"
              >
                <i className="icon-calendar"></i>
              </a>
            </li>
            <li className="dropdown">
              <a
                href="javascript:void(0);"
                className="dropdown-toggle icon-menu"
                data-toggle="dropdown"
              >
                <i className="icon-bell"></i>
                <span className="notification-dot"></span>
              </a>
              <ul className="dropdown-menu notifications">
                <li className="header">
                  <strong>You have 4 new Notifications</strong>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <div className="media">
                      <div className="media-left">
                        <i className="icon-info text-warning"></i>
                      </div>
                      <div className="media-body">
                        <p className="text">
                          Campaign <strong>Holiday Sale</strong> is nearly reach
                          budget limit.
                        </p>
                        <span className="timestamp">10:00 AM Today</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li style={{ marginLeft: "4px" }}>
                  <a href="javascript:void(0);">
                    <div className="media">
                      <div className="media-left">
                        <i className="icon-like text-success"></i>
                      </div>
                      <div className="media-body">
                        <p className="text">
                          Your New Campaign <strong>Holiday Sale</strong> is
                          approved.
                        </p>
                        <span className="timestamp">11:30 AM Today</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li style={{ marginLeft: "4px" }}>
                  <a href="javascript:void(0);">
                    <div className="media">
                      <div className="media-left">
                        <i className="icon-pie-chart text-info"></i>
                      </div>
                      <div className="media-body">
                        <p className="text">
                          Website visits from Twitter is 27% higher than last
                          week.
                        </p>
                        <span className="timestamp">04:00 PM Today</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li style={{ marginLeft: "4px" }}>
                  <a href="javascript:void(0);">
                    <div className="media">
                      <div className="media-left">
                        <i className="icon-info text-danger"></i>
                      </div>
                      <div className="media-body">
                        <p className="text">
                          Error on website analytics configurations
                        </p>
                        <span className="timestamp">Yesterday</span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="footer">
                  <a href="javascript:void(0);" className="more">
                    See all notifications
                  </a>
                </li>
              </ul>
            </li>
            <li style={{ marginLeft: "4px" }}>
              <a href="app-chat.html" className="icon-menu d-none d-sm-block">
                <i className="icon-settings"></i>
              </a>
            </li>
            <li style={{ marginLeft: "4px" }}>
              <a href="javascript:void(0);" className="dropdown-toggle btn btn-em" data-toggle="dropdown">Switch Profile</a>
              <ul className="dropdown-menu dropdown-menu-right switch-profile-flyout">
                <li className="pb-1">
                  <div className="d-flex align-items-center">
                    <img
                      src={userIcon}
                      className="rounded-circle user-photo"
                      alt="User Profile Picture"
                    />
                    <span>{userName}</span>
                  </div>
                </li>
                <li className="divider my-2"></li>
                <li className={individualLink ? 'd-none' : userType === 1 ? 'selected' : ''}>
                  <a href="javascript:void(0);" onClick={handleIndividualProfile}>
                    <i className="bi bi-person"></i> Individual Member
                  </a>
                  <label htmlFor="IndividualCheck" hidden></label>
                  <input type="radio" id="IndividualCheck" name="selectProfile" checked={userType === 1 ? 'checked' : ''} value="" />
                </li>
                <li className={groupLink ? 'd-none' : userType === 2 ? 'selected' : ''}>
                  <a href="javascript:void(0);" onClick={handleGroupProfile}>
                    <i className="bi bi-people"></i> Group Member
                  </a>
                  <label htmlFor="IndividualCheck" hidden></label>
                  <input type="radio" id="IndividualCheck" name="selectProfile" checked={userType === 2 ? 'checked' : ''} value="" />
                </li>
                <li className={businessLink ? 'd-none' : userType === 3 ? 'selected' : ''}>
                  <a href="javascript:void(0);" onClick={handleBusinessProfile}>
                    <i className="bi bi-building"></i> Business Member
                  </a>
                  <label htmlFor="IndividualCheck" hidden></label>
                  <input type="radio" id="IndividualCheck" name="selectProfile" checked={userType === 3 ? 'checked' : ''} value="" />
                </li>
                <li className={individualLink ? '' : 'd-none'}>
                  <a href="javascript:void(0);" className="link" onClick={handleIndividualShow}>
                    <i className="bi bi-person"></i> Create Individual Account
                  </a>
                </li>
                <li className={groupLink ? '' : 'd-none'}>
                  <a href="javascript:void(0);" className="link" onClick={handleShow}>
                    <i className="bi bi-people"></i> Create Group Account
                  </a>
                </li>
                <li className={businessLink ? '' : 'd-none'}>
                  <a href="javascript:void(0);" className="link" onClick={handleBusinessShow}>
                    <i className="bi bi-building"></i> Create Busines Account
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      {/* Create Individual member Modal */}
      <Modal size="lg" show={individualModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Create Individual Member
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="FormArea w-100">
            <Row>
              <Col>
                <p>We have your all personal details. If want to create Individual member profile, Please click on 'Confirm' button.</p>
                <p>Are you sure want to create Individual member profile?</p>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className="btn" variant="primary" type="submit" onClick={confirmIndividual}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Group Detail Modal */}
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Group Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {error && <p style={{ color: "red" }}>{error.group_email[0]}</p>} */}
          <Form className="FormArea w-100">
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="groupEmail">Email Address for Group</Form.Label>
                  <Form.Control type="text" name="groupEmail" value={groupdetails.groupEmail || ''} onChange={handleGroupDetails} />
                </Form.Group>
                {errors.groupEmail && <p style={{ color: "red" }}>{errors.groupEmail}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label>Group Name</Form.Label>
                  <Form.Control name="groupName" type="text" value={groupdetails.groupName}
                    onChange={handleGroupDetails} />
                </Form.Group>
                {errors.groupName && <p style={{ color: "red" }}>{errors.groupName}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label>Create Group Pin</Form.Label>
                  <Form.Control name="pin" type="number" value={groupdetails.pin}
                    onChange={handleGroupDetails} />
                </Form.Group>
                {errors.pin && <p style={{ color: "red" }}>{errors.pin}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label>Group Type</Form.Label>
                  <select
                    className="form-control"
                    id="gender"
                    name="type"
                    value={groupdetails.type}
                    onChange={handleGroupDetails}
                  >
                    <option value="">--Select--</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                  </select>
                </Form.Group>
                {errors.type && <p style={{ color: "red" }}>{errors.type}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label>Group Duration</Form.Label>
                  <Form.Control name="duration" type="text" value={groupdetails.duration}
                    onChange={handleGroupDetails} />
                </Form.Group>
                {errors.duration && <p style={{ color: "red" }}>{errors.duration}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label>City</Form.Label>
                  <Form.Control name="city" type="text" value={groupdetails.city}
                    onChange={handleGroupDetails} />
                </Form.Group>
                {errors.city && <p style={{ color: "red" }}>{errors.city}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label>Group Phone Number</Form.Label>
                  <Form.Control name="groupPhone" type="text" value={groupdetails.groupPhone}
                    onChange={handleGroupDetails} />
                </Form.Group>
                {errors.groupPhone && <p style={{ color: "red" }}>{errors.groupPhone}</p>}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className="btn" variant="primary" type="submit" onClick={submitGroupDetails}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Business Detail Modal */}
      <Modal size="lg" show={businessModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Business Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="FormArea w-100">
            <Row>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="business_name">Business Name</Form.Label>
                  <Form.Control type="text" id="business_name" name="business_name" value={businessdetails.business_name || ''} onChange={handleBusinessDetails} />
                </Form.Group>
                {errors.business_name && <p style={{ color: "red" }}>{errors.business_name}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="business_email">Business Email</Form.Label>
                  <Form.Control id="business_email" name="business_email" type="text" value={businessdetails.business_email}
                    onChange={handleBusinessDetails} />
                </Form.Group>
                {errors.business_email && <p style={{ color: "red" }}>{errors.business_email}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="business_number">Registered Business Number</Form.Label>
                  <Form.Control id="business_number" name="business_number" type="number" value={businessdetails.business_number}
                    onChange={handleBusinessDetails} />
                </Form.Group>
                {errors.business_number && <p style={{ color: "red" }}>{errors.business_number}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="business_phonenumber">Business Phone Number</Form.Label>
                  <Form.Control id="business_phonenumber" name="business_phonenumber" type="text" value={businessdetails.business_phonenumber}
                    onChange={handleBusinessDetails} />
                </Form.Group>
                {errors.business_phonenumber && <p style={{ color: "red" }}>{errors.business_phonenumber}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="business_city">City</Form.Label>
                  <Form.Control id="business_city" name="business_city" type="text" value={businessdetails.business_city}
                    onChange={handleBusinessDetails} />
                </Form.Group>
                {errors.business_city && <p style={{ color: "red" }}>{errors.business_city}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="business_address">Business Address</Form.Label>
                  <Form.Control id="business_address" name="business_address" type="text" value={businessdetails.business_address}
                    onChange={handleBusinessDetails} />
                </Form.Group>
                {errors.business_address && <p style={{ color: "red" }}>{errors.business_address}</p>}
              </Col>
              <Col lg={6} sm={12}>
                <Form.Group className="form-group">
                  <Form.Label htmlFor="business_postcode">Post code</Form.Label>
                  <Form.Control id="business_postcode" name="business_postcode" type="text" value={businessdetails.business_postcode}
                    onChange={handleBusinessDetails} />
                </Form.Group>
                {errors.business_postcode && <p style={{ color: "red" }}>{errors.business_postcode}</p>}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className="btn" variant="primary" type="submit" onClick={submitBusinessdetails}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading ? <LoadingSpinner /> : ''}
      {/* <LoadingSpinner /> */}
    </>
  );
}