import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from '../../../redux/action/user.action';
import Pagination from "react-js-pagination";
import "../css/main.css";
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function ProjectList({ status, fetching }) {
  const getallprojects = useSelector((state) => state.getAllProjectsReducer);
  // console.log("Get" + getallprojects.project.data.data[0].project_name)
  const { project, loading, error } = getallprojects;
  // console.log("Project " + project + "Loading " + loading)
  const dispatch = useDispatch();

  const [projectList, setProjectList] = useState({
    data: ''
  });
  const [listData, setListData] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  const fetchData = (pageNumber = 1) => {
    // console.log(pageNumber)
    if (status) {
      dispatch(getAllProjects(status, pageNumber))
      setListData(true)
    }
    else {
      dispatch(getAllProjects(null, pageNumber))
      setListData(true)
    }
  };

  useEffect(() => {
    if (!projectList.data && loading == undefined) {
      dispatch(getAllProjects())
    }
    // console.log(projectList.data, "List Data = " + listData)
    if(loading){
      setListData(true)
    }
    if (loading === false && listData) {
      setProjectList({
        data: project
      })
      setListData(false)
    }
    if (status && fetching) {
      fetchData()
    }
  }, [loading, projectList, listData, status, fetching]);

  return (
    <>

      {/* {console.log(projectList)} */}
      <div className="card">
        {loading ?
          <div className="table-responsive oddTable">
            <table className="table table-hover m-b-0">
              <thead className="thead-dark">
                <tr>
                  <th><span className="loading-box"></span></th>
                  <th><span className="loading-box"></span></th>
                  <th><span className="loading-box"></span></th>
                  <th><span className="loading-box"></span></th>
                  <th><span className="loading-box"></span></th>
                  <th><span className="loading-box"></span></th>
                  <th><span className="loading-box"></span></th>
                  <th><span className="loading-box"></span></th>
                </tr >
              </thead >
              <tbody>
                <tr>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                </tr>
                <tr>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                </tr>
                <tr>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                </tr>
                <tr>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                  <td><span className="loading-box"></span></td>
                </tr>
              </tbody>
            </table>
          </div> :
          (projectList?.data && projectList?.data.data.length ?
            <div className="table-responsive oddTable">
              <table className="table table-hover m-b-0">
                <thead className="thead-dark">
                  <tr>
                    <th>Project Name</th>
                    <th>Project ID</th>
                    <th>Total Members</th>
                    <th>Started On</th>
                    <th>Manage Role</th>
                    <th>Amount Target</th>
                    <th>Amount Raised</th>
                    <th>Actions</th>
                  </tr >
                </thead >
                <tbody>
                  {
                    projectList?.data?.data ? projectList.data.data.map((projects, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span className="list-name">
                              {projects.project_name}
                            </span>
                          </td>
                          <td>{projects.project_id}</td>
                          <td>1500</td>
                          <td>{projects.project_start_date}</td>
                          <td>
                            <a
                              href="javascript:void(0);"
                              className="btn btn-info btn-sm"
                            >
                              {projects.project_join_as}
                            </a>
                          </td>
                          <td>{projects.project_target_amount}</td>
                          <td>0</td>
                          <td>
                            {projects.project_status !== 'Draft' ? (
                              <a
                                href="#"
                                className="btn btn-success btn-sm"
                              >
                                Active
                              </a>
                            ) : (
                              <a
                                href="#"
                                className="btn btn-warning btn-sm"
                              >
                                Draft
                              </a>
                            )}
                          </td>
                        </tr>
                      );
                    }) : "Loading...."
                  }
                </tbody>
              </table >
              <div className="table-pagination">
                <Pagination
                  activePage={projectList?.data?.current_page ? projectList?.data?.current_page : 0}
                  itemsCountPerPage={projectList?.data?.per_page ? projectList?.data?.per_page : 0}
                  totalItemsCount={projectList?.data?.total ? projectList?.data?.total : 0}
                  onChange={(pageNumber) => {
                    fetchData(pageNumber)
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First Page"
                  lastPageText="Last Lage"
                />
              </div>
            </div >
            : <div className="table-responsive oddTable">
              <table className="table table-hover m-b-0">
                <tbody>
                  <tr className="mat-row">
                    <td className="Didnt-Project" colSpan={8}>
                      <img
                        src="assets/images/groupproject-icon.png"
                        alt="groupproject-icon"
                        className="img-responsive"
                      />
                      <h3>You Didn't Created To Any Project Yet!</h3>
                      <p>
                        Go to create new project and create new membership.
                        <br />
                        Joined or created proejcts by you will appear here.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        }
      </div >
    </>
  );
}
