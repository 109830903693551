import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../redux/action/user.action";
import Loader from "../components/Loader";
import Error from "../components/Error";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { toast } from "react-toastify";

export default function LoginScreen() {
  const loginreducer = useSelector((state) => state.loginReducer);
  const loading = loginreducer.loading;
  const error = loginreducer.error;
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [check, setCheck] = useState("");
  const [checkError, setCheckError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = (e) => {
    e.preventDefault();
    let isError = false;
    if (!check) {
      isError = true;
      setCheckError(
        "Please indicate that you accept the Terms and Conditions"
      );
    }else {
      setCheckError("");
    }
    if (isError) {
      return;
    }

    const coba_login = new FormData();
    coba_login.append("email", email);
    coba_login.append("password", password);
    dispatch(loginUser(coba_login));
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
      navigate("/login");
    }
    if (localStorage.getItem("currentUser")) {
      window.location.href = "/";
    }
  }, [error]);

  return (
    <>
      <Navbar />
      <div className="d-lg-flex half">
        <div
          className="bg order-1 order-md-2"
          style={{ backgroundImage: `url("assets/images/login-bg.jpg")` }}
        ></div>
        <div className="contents order-2 order-md-1">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-9 LogInFrom">
                <h3>Welcome back</h3>
                <p className="mb-4">
                  Please activate your account by filling these form:
                </p>
                {loginreducer.error && <Error error={error} />}
                {loading && <Loader />}

                <form className="FormLogin" onSubmit={login}>
                  <div className="floating-label">
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />

                    <label htmlFor="email">Email:</label>
                  </div>
                  <div className="floating-label">
                    <input
                      type="password"
                      placeholder="Password"
                      autoComplete="off"
                      value={password}
                      required
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <label htmlFor="password">Password:</label>
                  </div>
                  <div className="d-flex mt-4 mb-5 align-items-center">
                    <label className="control control--checkbox mb-0">
                      <span className="caption">
                        <span className="caption">
                          I have read and agree to the{" "}
                          <a
                            className="semi"
                            href="https://investorsincommunity.org/terms/"
                          >
                            Terms of Service
                          </a>
                        </span>
                      </span>
                      <input
                        type="checkbox"
                        onChange={(e) => setCheck(e.target.checked)}
                      />
                      <div style={{ color: "red" }}>{checkError}</div>
                      <div className="control__indicator"></div>
                    </label>
                    <span className="ml-auto">
                      <Link to="/forgot-password" className="forgot-pass">
                        Forgot Password?
                      </Link>
                    </span>
                  </div>
                  <div className="LogButtons text-center">
                    <button type="submit" className="btn login mr-3">
                      Log In
                    </button>
                    <Link className="btn Register" to="/register">
                      Register
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
